import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },

  palette: {
    mode: "light",

    primary: {
      light: "",
      main: "#FCE34D",
      dark: "",
    },

    background: {
      // default: "#f7f8f9",
      paper: "#f7f8f9",
    },

    secondary: {
      light: "",
      main: "#000",
      dark: "",
      contrastText: "#FFF",
    },
  },

  typography: {
    lineHeight: 1,
    color: "inherit",

    h1: {
      fontWeight: 700,
      fontSize: 72,
      lineHeight: "80px",
      color: "inherit",

      [breakpoints.down(992)]: {
        fontSize: 60,
        lineHeight: "72px",
      },
      [breakpoints.down(576)]: {
        fontSize: 34,
        lineHeight: "40px",
      },
    },

    h2: {
      fontSize: 48,
      fontWeight: 500,
      lineHeight: "56px",
      color: "inherit",

      [breakpoints.down(992)]: {
        fontSize: 34,
        lineHeight: "1!important",
      },
      [breakpoints.down(992)]: {
        fontSize: 34,
      },
    },

    h3: {
      fontSize: 39,
      fontWeight: "500",
      [breakpoints.down(992)]: {
        fontSize: 33,
      },
      [breakpoints.down(640)]: {
        fontSize: 20,
      },
    },

    h4: {
      fontSize: 28,
      fontWeight: "500",
      color: "inherit",
      [breakpoints.down(640)]: {
        fontSize: 23,
      },
    },

    h5: {
      fontSize: 20,
      fontWeight: "500",
      lineHeight: "23px",
      color: "inherit",

      [breakpoints.down(720)]: {
        fontSize: 16,
        fontWeight: "normal",
      },
    },

    h6: {
      fontSize: 19,
      lineHeight: "23px",
      color: "inherit",
      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },

    body2: {
      fontSize: 16,
      lineHeight: "19px",
      fontWeight: "normal",
      color: "inherit",

      [breakpoints.down(720)]: {
        fontSize: 16,
        lineHeight: "19px",
      },
    },

    body1: {
      fontSize: 18,
      fontWeight: "500",
      lineHeight: "normal",
      color: "inherit",

      [breakpoints.down(576)]: {
        fontSize: 16,
      },
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          boxShadow: "none",

          "&.MuiButton-sizeLarge": {
            padding: "14px 28px",
            fontWeight: 600,
            fontSize: 18,
            lineHeight: 1,

            [breakpoints.down(768)]: {
              padding: "19px 28px",
            },
          },

          "&.MuiButton-sizeMedium": {
            padding: "10px 24px",
            fontWeight: 600,
            fontSize: 16,
          },

          "&.MuiButton-sizeSmall": {
            padding: "8px 16px",
            fontWeight: 600,
            fontSize: 14,
          },

          "&.Mui-disabled": {
            backgroundColor: "#E7EAEE!important",
            color: "#A0ABBB",
          },

          "&.MuiButton-outlined": {
            padding: "12px 28px",
            borderWidth: "2px",

            [breakpoints.down(768)]: {
              padding: "17px 28px",
            },

            "&:hover": {
              borderWidth: "2px",
            },
          },

          "&.MuiButton-outlinedSecondary": {
            borderColor: "#000",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          "& img": {
            width: 30,
            height: 30,
            objectFit: "contain",
            marginRight: 20,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "transparent",
            boxShadow: "0px 1px 0px #E2E8F0",
          },

          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            backgroundColor: "#FFF",
            "&.Mui-disabled fieldset": {
              borderColor: "transparent",
            },
          },

          "& .MuiInputLabel-formControl": {
            color: "#B8C0CC",
            fontWeight: 300,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "90%",
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          // borderRadius: 16,
          "& .MuiOutlinedInput-root": {
            // borderRadius: 12,
          },

          "& .MuiSelect-select": {
            // borderRadius: 12,
            // backgroundColor: "#fff",
            alignItems: "center",
            display: "flex",
          },

          "& .MuiInputLabel-formControl": {
            color: "#B8C0CC",
            fontWeight: 300,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "90%",
          },

          "& fieldset": {
            borderColor: "transparent",
            // boxShadow: "0px 1px 0px #E2E8F0",
          },
          "& img": {
            // display: "none",
            width: 25,
            height: 25,
            objectFit: "contain",
            marginRight: 20,
          },
        },
      },
    },
  },
});
