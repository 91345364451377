import React, { lazy, useEffect, useMemo, useState } from "react";
import cookie from "cookie_js";
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrivateRoute } from "./utils/PrivateRoute";
import { useDispatch } from "react-redux";
import { getBalances, getProfileData } from "./redux/reducers/myProfile";
import { getСurrenciesList } from "./redux/reducers/Сurrencies";
import NotFound from "./pages/NotFound";
import { getInternalQuantity } from "./redux/reducers/internal";
import { theme } from "./theme";
import { isAuthenticated, useAuth } from "../src/utils/isAuthenticated";
import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";
// import img from "./assets/images/close.png";
import "swiper/swiper.min.css";

// import "./js/pm.js";

const Site = lazy(() => import("./Router/siteRoute"));
const AuthRoute = lazy(() => import("./Router/authRoute"));
const CabinetRoute = lazy(() => import("./Router/CabinetRoute"));

function App() {
  const token = cookie.get("token_netex");
  const isAuth = useAuth();

  const pushToken = new URLSearchParams(window.location.search).get("token_netex");
  const isMobile = new URLSearchParams(window.location.search).get("isMobile");

  const isMobileFromCookie = cookie.get("isMobile");
  const pushAndroidTokenFromCookie = cookie.get("pushkey_android");

  // eslint-disable-next-line eqeqeq
  if (isMobile != null || isMobileFromCookie != undefined) {
    if (isMobile != null)
      document.cookie = `isMobile=${isMobile}; path=/; expires=Tue, 19 Jan 3038 03:14:07 GMT`;

    if (pushToken !== null && pushAndroidTokenFromCookie === undefined) {
      document.cookie = `pushkey_android=${pushToken}; path=/; expires=Tue, 19 Jan 3038 03:14:07 GMT`;
    }
    // if (!isAuthenticated()) {
    //   if (!document.location.pathname.includes("terms-of-use")) {
    //     if (!document.location.pathname.includes("accounts")) {
    //       document.location.href = "/accounts/login";
    //     }
    //   }
    // }
  }

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getСurrenciesList());
    localStorage.setItem("mode", "light");

    if (!isAuth)
      cookie.removeSpecific("token_netex", {
        path: "/",
        domain: window.location.hostname,
      });
    if (token) {
      dispatch(getProfileData());
      // dispatch(getBalances());
    }

    console.log("%c%s", "color: red; font-size: 20px", "WARNING");
    console.log(
      "%c%s",
      "font-size: 20px",
      "This browser feature is for developers. If someone told you to write here any data, they are scammers. If you paste data, you will give scammers access to your account."
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getInternalQuantity());
    window.scrollTo(0, 0);
    if (token) dispatch(getBalances());

    // eslint-disable-next-line
  }, [location.pathname]);

  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        localStorage.setItem("mode", mode === "light" ? "dark" : "light");
      },
    }),
    []
  );

  const breakpoints = createBreakpoints({});

  const theme = useMemo(
    () =>
      createTheme(
        mode === "light"
          ? {
              breakpoints: {
                values: {
                  xs: 0,
                  sm: 576,
                  md: 992,
                  lg: 1200,
                  xl: 1440,
                },
              },

              palette: {
                mode: "light",

                primary: {
                  light: "",
                  main: "#FCE34D",
                  dark: "",
                },

                background: {
                  default: "#f7f8f9",
                  paper: "#f7f8f9",
                },

                secondary: {
                  light: "",
                  main: "#000",
                  dark: "",
                  contrastText: "#FFF",
                },
              },

              typography: {
                lineHeight: 1,
                color: "inherit",

                h1: {
                  fontWeight: 700,
                  fontSize: 72,
                  lineHeight: "80px",
                  color: "inherit",

                  [breakpoints.down(992)]: {
                    fontSize: 60,
                    lineHeight: "72px",
                  },
                  [breakpoints.down(576)]: {
                    fontSize: 34,
                    lineHeight: "40px",
                  },
                },

                h2: {
                  fontSize: 48,
                  fontWeight: 500,
                  lineHeight: "56px",
                  color: "inherit",

                  [breakpoints.down(992)]: {
                    fontSize: 34,
                    lineHeight: "1!important",
                  },
                  [breakpoints.down(992)]: {
                    fontSize: 34,
                  },
                },

                h3: {
                  fontSize: 39,
                  fontWeight: "500",
                  [breakpoints.down(992)]: {
                    fontSize: 33,
                  },
                  [breakpoints.down(640)]: {
                    fontSize: 20,
                  },
                },

                h4: {
                  fontSize: 28,
                  fontWeight: "500",
                  color: "inherit",
                  [breakpoints.down(640)]: {
                    fontSize: 23,
                  },
                },

                h5: {
                  fontSize: 20,
                  fontWeight: "500",
                  lineHeight: "23px",
                  color: "inherit",

                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    fontWeight: "normal",
                  },
                },

                h6: {
                  fontSize: 19,
                  lineHeight: "23px",
                  color: "inherit",
                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    lineHeight: "19px",
                  },
                },

                body2: {
                  fontSize: 16,
                  lineHeight: "19px",
                  fontWeight: "normal",
                  color: "inherit",

                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    lineHeight: "19px",
                  },
                },

                body1: {
                  fontSize: 18,
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "inherit",

                  [breakpoints.down(576)]: {
                    fontSize: 16,
                  },
                },
              },

              components: {
                MuiButton: {
                  styleOverrides: {
                    root: {
                      borderRadius: 8,
                      textTransform: "none",
                      boxShadow: "none",

                      "&.MuiButton-sizeLarge": {
                        padding: "14px 28px",
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1,

                        [breakpoints.down(768)]: {
                          padding: "19px 28px",
                        },
                      },

                      "&.MuiButton-sizeMedium": {
                        padding: "10px 24px",
                        fontWeight: 600,
                        fontSize: 16,
                      },

                      "&.MuiButton-sizeSmall": {
                        padding: "8px 16px",
                        fontWeight: 600,
                        fontSize: 14,
                      },

                      "&.Mui-disabled": {
                        backgroundColor: "#E7EAEE!important",
                        color: "#A0ABBB",
                      },

                      "&.MuiButton-outlined": {
                        padding: "12px 28px",
                        borderWidth: "2px",

                        [breakpoints.down(768)]: {
                          padding: "17px 28px",
                        },

                        "&:hover": {
                          borderWidth: "2px",
                        },
                      },

                      "&.MuiButton-outlinedSecondary": {
                        borderColor: "#000",
                      },
                    },
                  },
                },

                MuiMenuItem: {
                  styleOverrides: {
                    root: {
                      fontWeight: 300,
                      "& img": {
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                      },
                    },
                  },
                },

                MuiPaper: {
                  styleOverrides: {
                    root: {
                      borderRadius: 0,
                    },
                  },
                },

                MuiTextField: {
                  styleOverrides: {
                    root: {
                      "& fieldset": {
                        borderColor: "transparent",
                        boxShadow: "0px 1px 0px #E2E8F0",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8,
                        backgroundColor: "#FFF",
                        "&.Mui-disabled fieldset": {
                          borderColor: "transparent",
                        },
                      },

                      "& .MuiInputLabel-formControl": {
                        color: "#B8C0CC",
                        fontWeight: 300,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "90%",
                      },
                    },
                  },
                },

                MuiFormControl: {
                  styleOverrides: {
                    root: {
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8,
                      },

                      "& .MuiSelect-select": {
                        borderRadius: 8,
                        backgroundColor: "#fff",
                        alignItems: "center",
                        display: "flex",
                      },

                      "& .MuiInputLabel-formControl": {
                        color: "#B8C0CC",
                        fontWeight: 300,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "90%",
                      },

                      "& fieldset": {
                        borderColor: "transparent",
                        boxShadow: "0px 1px 0px #E2E8F0",
                      },
                      "& img": {
                        // display: "none",
                        width: 25,
                        height: 25,
                        objectFit: "contain",
                        marginRight: 20,
                      },
                    },
                  },
                },
              },
            }
          : {
              breakpoints: {
                values: {
                  xs: 0,
                  sm: 576,
                  md: 992,
                  lg: 1200,
                  xl: 1440,
                },
              },

              palette: {
                mode: mode,

                primary: {
                  light: "",
                  main: "#FCE34D",
                  dark: "",
                },

                background: {
                  // default: "#f7f8f9",
                  // paper: "#f7f8f9",
                },

                secondary: {
                  light: "",
                  main: "#000",
                  dark: "",
                  contrastText: "#FFF",
                },
              },

              typography: {
                lineHeight: 1,
                color: "inherit",

                h1: {
                  fontWeight: 700,
                  fontSize: 72,
                  lineHeight: "80px",
                  color: "inherit",

                  [breakpoints.down(992)]: {
                    fontSize: 60,
                    lineHeight: "72px",
                  },
                  [breakpoints.down(576)]: {
                    fontSize: 34,
                    lineHeight: "40px",
                  },
                },

                h2: {
                  fontSize: 48,
                  fontWeight: 500,
                  lineHeight: "56px",
                  color: "inherit",

                  [breakpoints.down(992)]: {
                    fontSize: 34,
                    lineHeight: "1!important",
                  },
                  [breakpoints.down(992)]: {
                    fontSize: 34,
                  },
                },

                h3: {
                  fontSize: 39,
                  fontWeight: "500",
                  [breakpoints.down(992)]: {
                    fontSize: 33,
                  },
                  [breakpoints.down(640)]: {
                    fontSize: 20,
                  },
                },

                h4: {
                  fontSize: 28,
                  fontWeight: "500",
                  color: "inherit",
                  [breakpoints.down(640)]: {
                    fontSize: 23,
                  },
                },

                h5: {
                  fontSize: 20,
                  fontWeight: "500",
                  lineHeight: "23px",
                  color: "inherit",

                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    fontWeight: "normal",
                  },
                },

                h6: {
                  fontSize: 19,
                  lineHeight: "23px",
                  color: "inherit",
                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    lineHeight: "19px",
                  },
                },

                body2: {
                  fontSize: 16,
                  lineHeight: "19px",
                  fontWeight: "normal",
                  color: "inherit",

                  [breakpoints.down(720)]: {
                    fontSize: 16,
                    lineHeight: "19px",
                  },
                },

                body1: {
                  fontSize: 18,
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "inherit",

                  [breakpoints.down(576)]: {
                    fontSize: 16,
                  },
                },
              },

              components: {
                MuiButton: {
                  styleOverrides: {
                    root: {
                      borderRadius: 8,
                      textTransform: "none",
                      boxShadow: "none",

                      "&.MuiButton-sizeLarge": {
                        padding: "14px 28px",
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1,

                        [breakpoints.down(768)]: {
                          padding: "19px 28px",
                        },
                      },

                      "&.MuiButton-sizeMedium": {
                        padding: "10px 24px",
                        fontWeight: 600,
                        fontSize: 16,
                      },

                      "&.MuiButton-sizeSmall": {
                        padding: "8px 16px",
                        fontWeight: 600,
                        fontSize: 14,
                      },

                      // "&.Mui-disabled": {
                      //   backgroundColor: "#E7EAEE!important",
                      //   color: "#A0ABBB",
                      // },

                      "&.MuiButton-outlined": {
                        padding: "12px 28px",
                        borderWidth: "2px",

                        [breakpoints.down(768)]: {
                          padding: "17px 28px",
                        },

                        "&:hover": {
                          borderWidth: "2px",
                        },
                      },

                      "&.MuiButton-outlinedSecondary": {
                        borderColor: "#000",
                      },
                    },
                  },
                },

                MuiMenuItem: {
                  styleOverrides: {
                    root: {
                      fontWeight: 300,
                      "& img": {
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                      },
                    },
                  },
                },

                MuiPaper: {
                  styleOverrides: {
                    root: {
                      borderRadius: 0,
                    },
                  },
                },

                MuiTextField: {
                  styleOverrides: {
                    root: {
                      "& fieldset": {
                        borderColor: "transparent",
                        // boxShadow: "0px 1px 0px #E2E8F0",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8,
                        // backgroundColor: "#FFF",
                        "&.Mui-disabled fieldset": {
                          borderColor: "transparent",
                        },
                      },

                      "& .MuiInputLabel-formControl": {
                        color: "#B8C0CC",
                        fontWeight: 300,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "90%",
                      },
                    },
                  },
                },

                MuiFormControl: {
                  styleOverrides: {
                    root: {
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8,
                      },

                      "& .MuiSelect-select": {
                        borderRadius: 8,
                        // backgroundColor: "#fff",
                        alignItems: "center",
                        display: "flex",
                      },

                      "& .MuiInputLabel-formControl": {
                        color: "#B8C0CC",
                        fontWeight: 300,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "90%",
                      },

                      "& fieldset": {
                        borderColor: "transparent",
                        // boxShadow: "0px 1px 0px #E2E8F0",
                      },
                      "& img": {
                        // display: "none",
                        width: 25,
                        height: 25,
                        objectFit: "contain",
                        marginRight: 20,
                      },
                    },
                  },
                },
              },
            }
      ),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          flexDirection="column"
        >
          <img src={img} alt="" />

          <Typography
            maxWidth="70%"
            textAlign="center"
            color="#000"
            variant="h1"
          >
            Плановые технические работы на сайте
          </Typography>
          <Typography
            mt={2}
            maxWidth="70%"
            textAlign="center"
            color="#000"
            variant="h3"
          >
            Это может занять некоторое время, просим прощения за предоставленные
            неудобства{" "}
          </Typography>
        </Box> */}
      <Switch>
        <Redirect exact from="/" to="/main/home" />
        <Route
          path="/main/"
          component={() => (
            <Site colorMode={colorMode} mode={mode} t={t} i18n={i18n} />
          )}
        />
        <Route
          path="/accounts/"
          component={() => <AuthRoute t={t} i18n={i18n} mode={mode} />}
        />
        <PrivateRoute
          path="/dashboard/"
          component={() => <CabinetRoute t={t} i18n={i18n} mode={mode} />}
        />
        <Route exact={true} path="*">
          <NotFound />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
