import { Main } from "../../api";

let initialState = {
  mode: 'light',
  MainList: [],
  isDrawerOpen: false,
  isDrawerLanguageOpen: false,
  balance: 0,
  value: 0,
  isCurrenciesDraderOpen: {
    open: false,
    name: "Bitcoin",
    currency: "BTC",
  },
};

const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAIN_PAGE": {
      return {
        ...state,
        MainList: action.data,
      };
    }

    case "SET_MODE": {
      return {
        ...state,
        mode: action.data,
      };
    }

    case "SET_VALUE": {
      return {
        ...state,
        value: action.data,
      };
    }

    case "DRAWER_OPEN": {
      return {
        ...state,
        isDrawerOpen: action.data,
      };
    }

    case "DRAWER_LANGUAGE_OPEN": {
      return {
        ...state,
        isDrawerLanguageOpen: action.data,
      };
    }

    case "SET_BALANCE_GLOBAL": {
      return {
        ...state,
        balance: action.data,
      };
    }

    case "SET_CURRENCIES_DRAWER": {
      return {
        ...state,
        isCurrenciesDraderOpen: action.data,
      };
    }

    default:
      return state;
  }
};

export const setMainPage = (data) => ({ type: "SET_MAIN_PAGE", data });
export const setMode = (data) => ({ type: "SET_MODE", data });
export const setValue = (data) => ({ type: "SET_VALUE", data });
export const setDrawerOpen = (data) => ({ type: "DRAWER_OPEN", data });
export const setBalanceGlobal = (data) => ({
  type: "SET_BALANCE_GLOBAL",
  data,
});
export const setDrawerLanguageOpen = (data) => ({
  type: "DRAWER_LANGUAGE_OPEN",
  data,
});

export const setCurrenciesDrawer = (data) => ({
  type: "SET_CURRENCIES_DRAWER",
  data,
});

export const getMainList = () => {
  return (dispath) => {
    Main.getMain().then((response) => dispath(setMainPage(response)));
  };
};
export default MainReducer;
